import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAuth } from '@utils/useAuth';

const Home = () => {
  const {
    auth: { id },
  } = useAuth();

  const router = useRouter();
  useEffect(() => {
    // TODO checking if id is 0 is wrong and should be fixed
    let redirectPath = '/campaigns';
    if (
      router.query.source &&
      router.query.addedNewDomain &&
      router.query.shop
    ) {
      const { source, addedNewDomain, shop } = router.query;
      redirectPath = `${redirectPath}?source=${source}&addedNewDomain=${addedNewDomain}&shop=${shop}`;
    }
    if (id !== 0) {
      router.push(redirectPath);
    }
  }, [id, router]);

  return <></>;
};

export default Home;
